import { useEffect } from 'react'
import { navigate } from 'gatsby'

const IndexPage = () => {
  useEffect(() => {
    navigate('/de')
  }, [])
  //console.log(query)
  return null
}

export default IndexPage
